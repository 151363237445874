<template>
  <div class="empty-layout">
    <NuxtPage />
  </div>
</template>

<style scoped>
  .empty-layout {
    display: flex;
    justify-content: center;
    padding-top: 5rem;
    height: 100%;
    width: 100%;
  }
</style>

